/* eslint-disable fp/no-mutation */
import e from 'e_modules'
import i18next from 'i18next'
import _ from 'lodash'
import {showToast} from 'lib/component/common/atoms/Toast'
import {getSiteSettingsDetails} from 'lib/store'
import {SOURCE_TYPE, TOAST_TYPE} from './constants'
import {getDeviceDetails, getSource} from './utility'

// Get User Details
export function getUserDetails() {
  return getSiteSettingsDetails().userDetails
}

// Get User Id
export function getUserId() {
  return getDeviceDetails('userId', 'userId')
}

// Get Device UId
export function getDeviceUId() {
  // TODO
  return 234123
}

// Get User Email Id
export function getUserEmail() {
  const userDetails = e.storage.get('userDetails')
  const email =
    (userDetails && userDetails.email) ||
    e.storage.get('email') ||
    window.getCookie('email') ||
    localStorage.getItem('email')

  return getUserDetails().email || email
}

// Set User Email Id
export function setUserEmail(emailId) {
  const email = emailId && emailId.toString()

  // Set email in localStorage and keeps a copy in e.storage
  e.storage.set('email', email)
}

// System Owner
export function isSystemOwner() {
  return getUserDetails().isOwner
}

// System Host
export function isSystemHost() {
  return getUserDetails().isHost
}

// Installer
export function isInstaller() {
  return getUserDetails().isInstaller
}

// Maintainer
export function isMaintainer() {
  return getUserDetails().isMaintainer
}

// Enphase User
export function isEnphaseUser() {
  return getUserDetails().isEnphaseUser
}

// Demo User
export function isDemoUser() {
  return getUserDetails().isDemoUser
}

// User Access :: Type 1
export function isUserHasAccessT1() {
  const source = getSource()
  const hasAccess = isSystemOwner() || isSystemHost() || isInstaller() || isMaintainer()

  if (source && source !== SOURCE_TYPE.ENHO) {
    return hasAccess || isEnphaseUser()
  }

  return hasAccess
}

// Check User Access
export function isUserHasAccess(showUserAccessToast = true) {
  let hasAccess = true
  const maskedOwnerEmail = getSiteSettingsDetails().ownerOrHostMaskedEmail

  if (isDemoUser()) {
    showUserAccessToast &&
      showToast({
        type: TOAST_TYPE.INFO,
        message: i18next.t('bp396'),
        autoClose: 3000,
      })
    hasAccess = false
  } else if (!isUserHasAccessT1()) {
    showUserAccessToast &&
      showToast({
        type: TOAST_TYPE.ERROR,
        message: !_.isNil(maskedOwnerEmail) ? `${i18next.t('bp520', { email: maskedOwnerEmail })}` : `${i18next.t('bp194')} ${i18next.t('bp195')}`,
        autoClose: 3000,
      })
    hasAccess = false
  }

  return hasAccess
}

// Check Demo User Blocked
export function isDemoUserBlocked() {
  const demoUser = isDemoUser()

  if (demoUser) {
    showToast({
      type: TOAST_TYPE.INFO,
      message: i18next.t('bp396'),
      autoClose: 3000,
    })
  }

  return demoUser
}

import React from 'react'

const WaterHeater = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Water Heater">
      <path
        id="Union"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M6.16669 2C5.33826 2 4.66669 2.67157 4.66669 3.5V5.74324V11.8333C4.66669 12.7187 4.92238 13.5444 5.36394 14.2405L5.42055 14.4042L5.4628 14.3897C6.01313 15.1855 6.81334 15.7954 7.75002 16.1058V17.1667C7.75002 17.5809 8.08581 17.9167 8.50002 17.9167C8.91423 17.9167 9.25002 17.5809 9.25002 17.1667V16.3333H9.83335H10.4167V17.1667C10.4167 17.5809 10.7525 17.9167 11.1667 17.9167C11.5809 17.9167 11.9167 17.5809 11.9167 17.1667V16.1058C12.8534 15.7954 13.6536 15.1855 14.2039 14.3897L14.2461 14.4042L14.3027 14.2406C14.7443 13.5444 15 12.7187 15 11.8333V5.74324V3.5C15 2.67157 14.3284 2 13.5 2H6.16669ZM11.1667 15.0833C11.2996 15.0833 11.4244 15.1179 11.5326 15.1785C12.183 14.978 12.7525 14.593 13.18 14.0846C12.1102 13.8118 10.989 13.6667 9.83334 13.6667C8.67766 13.6667 7.55645 13.8118 6.48669 14.0846C6.91418 14.593 7.48368 14.978 8.13408 15.1785C8.24232 15.1179 8.36714 15.0833 8.50002 15.0833C8.72134 15.0833 8.92026 15.1792 9.05754 15.3317C9.09379 15.3328 9.13017 15.3333 9.16669 15.3333H9.83335H10.5C10.5365 15.3333 10.5729 15.3328 10.6092 15.3317C10.7464 15.1792 10.9454 15.0833 11.1667 15.0833ZM9.83334 12.6667C11.1809 12.6667 12.4863 12.8506 13.7252 13.195C13.9022 12.7765 14 12.3163 14 11.8333V5.74324V3.5C14 3.22386 13.7762 3 13.5 3H6.16669C5.89054 3 5.66669 3.22386 5.66669 3.5V5.74324V11.8333C5.66669 12.3163 5.76452 12.7765 5.94146 13.195C7.18042 12.8506 8.48574 12.6667 9.83334 12.6667ZM10.8593 7.3359C10.8593 7.89247 10.4081 8.34367 9.85154 8.34367C9.29496 8.34367 8.84377 7.89247 8.84377 7.3359C8.84377 6.77932 9.29496 6.32812 9.85154 6.32812C10.4081 6.32812 10.8593 6.77932 10.8593 7.3359ZM11.8593 7.3359C11.8593 8.44476 10.9604 9.34367 9.85154 9.34367C8.74268 9.34367 7.84377 8.44476 7.84377 7.3359C7.84377 6.22703 8.74268 5.32812 9.85154 5.32812C10.9604 5.32812 11.8593 6.22703 11.8593 7.3359Z"
        fill="#6B7073"
      />
    </g>
  </svg>
)

WaterHeater.propTypes = {}

WaterHeater.defaultProps = {}

export default WaterHeater
